import { render, staticRenderFns } from "./king-kong.vue?vue&type=template&id=810a5f42&scoped=true"
import script from "./king-kong.vue?vue&type=script&lang=js"
export * from "./king-kong.vue?vue&type=script&lang=js"
import style0 from "./king-kong.vue?vue&type=style&index=0&id=810a5f42&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "810a5f42",
  null
  
)

export default component.exports